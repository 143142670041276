import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeShuffle,
    placeTest
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 26, 21, 4],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.suspendUpdate();

brd1.options.layer['image'] =14;
//Variables
placeLogo(brd1);
var a = 1;
var b = 2;
var c = 3;
var d = 4;
var arr = [a, b, c, d];
var j=1;
const cols = ['black', 'red', 'blue', 'green'];
//Title and subtitle
placeTitle(brd1, 'Decimal Place', '');
var lft = placeRightText(brd1, 15.8, 23, 'In the number below, select the decimal place of');
var rgt =placeLeftText(brd1, 16.20, 23, function(){return arr[j]});
rgt.setAttribute({
  color:function(){return cols[j]}
});
//brd1.create('image', ['/assets/oneR.svg', [9, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/twoR.svg', [11.5, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/threeR.svg', [14, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
var polynomial1 = brd1.create('text', [8.2, 20, function(){return  a + '.'  }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var polynomial2 = brd1.create('text', [9.5, 20, function(){return  '' + b + '' }],{fixed: true, color:'red', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var polynomial3 = brd1.create('text', [10.5, 20, function(){return ' '+ c }],{fixed: true, color:'blue', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
var polynomial4 = brd1.create('text', [11.5, 20, function(){return ' '+ d }],{fixed: true, color:'green', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
polynomial1.on('down',function(){
      txt0.setAttribute({visible: true});
      dash0.setAttribute({visible: true});
  });
polynomial2.on('down',function(){
      txt1.setAttribute({visible: true});
      dash1.setAttribute({visible: true});
  });
polynomial3.on('down',function(){
      txt2.setAttribute({visible:true});
      dash2.setAttribute({visible: true});
    });
polynomial4.on('down',function(){
      txt3.setAttribute({visible:true});
      dash3.setAttribute({visible: true});
        });
//////////////////////////////////////////////////////
var dash1 = placeLine(brd1, [9.5, 16], [9.5, 19.4], 2, 'red');
var dash2 = placeLine(brd1, [10.5, 14], [10.5, 19.4], 2, 'blue');
var dash3 = placeLine(brd1, [11.5, 12], [11.5, 19.4], 2, 'green');
var dash0 = placeLine(brd1, [9.5, 16.6], [9.5, 19.], 2, 'brown');
dash0.setAttribute({visible:false});
dash1.setAttribute({visible:false});
dash2.setAttribute({visible:false});
dash3.setAttribute({visible:false});
var i=0;
var ans1 = brd1.create('text', [2, 16, 'Tenth'],{display:'internal',highlight:false,fixed: true, anchorX: 'left', anchorY: 'middle', color:'gray', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var ans2 = brd1.create('text', [2, 14, 'Hundredth'],{display:'internal',highlight:false,fixed: true, anchorX: 'left', anchorY: 'middle', color:'gray', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var ans3 = brd1.create('text', [2, 12, 'Thousandth'],{display:'internal',highlight:false, fixed: true, anchorX: 'left', anchorY: 'middle', color:'gray', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
ans1.on('down', function(){
  ans1.setAttribute({color:'black'});
  ans2.setAttribute({color:'gray'});
  ans3.setAttribute({color:'gray'});
  chk[0].setAttribute({visible:true});
  chk[1].setAttribute({visible:false});
  chk[2].setAttribute({visible:false});
  i=1;
})
ans2.on('down', function(){
  ans2.setAttribute({color:'black'});
  ans1.setAttribute({color:'gray'});
  ans3.setAttribute({color:'gray'});
  chk[0].setAttribute({visible:false});
  chk[1].setAttribute({visible:true});
  chk[2].setAttribute({visible:false});
  i=2;
})
ans3.on('down', function(){
  ans3.setAttribute({color:'black'});
  ans2.setAttribute({color:'gray'});
  ans1.setAttribute({color:'gray'});
  chk[0].setAttribute({visible:false});
  chk[1].setAttribute({visible:false});
  chk[2].setAttribute({visible:true});
  i=3;
})
///////////////////////////////////////////////////////////v
var rght = placeImage(brd1, '/assets/check.svg', 9,8,2,0);
rght.setAttribute({visible:false});
var chk =[];
chk[0]=placeImage(brd1, '/assets/finger.svg', 0.8,15.5,1,0);
chk[1]=placeImage(brd1, '/assets/finger.svg', 0.8,13.5,1,0);
chk[2]=placeImage(brd1, '/assets/finger.svg', 0.8,11.5,1,0);
chk[0].setAttribute({visible:false});
chk[1].setAttribute({visible:false});
chk[2].setAttribute({visible:false});
//////////////////////////////////////////////////////////////
var wrng =[];
wrng[0]= placeImage(brd1, '/assets/cross.svg', 9,8,2,0);
wrng[1]= placeImage(brd1, '/assets/cross.svg', 0,13.5,1,0);
wrng[2]= placeImage(brd1, '/assets/cross.svg', 0,11.5,1,0);
wrng[0].setAttribute({visible:false});
wrng[1].setAttribute({visible:false});
wrng[2].setAttribute({visible:false});
///////////////////////////////////////////////////////////
var txt0 = brd1.create('text', [9.3, 19., '1s Place'],{visible:false, display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'brown', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt1 = brd1.create('text', [9.3, 16, 'Tenth'],{visible:false, display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'red', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt2 = brd1.create('text', [10.3, 14, 'Hundredth'],{visible:false,display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'blue', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var txt3 = brd1.create('text', [11.3, 12, 'Thousandth'],{visible:false,display:'internal',highlight:false, fixed: true, anchorX: 'right', anchorY: 'middle', color:'green', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);

var analytics = placeShuffle(brd1);
//placeImage(brd1, '/assets/shuffle.svg', 8.75, 5, 1.5, 0);
analytics.setLabel('Tap to Shuffle')
analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
analytics.on('over', function () {this.label.setAttribute({visible:true});});
analytics.on('out', function () {this.label.setAttribute({visible:false});});

var erase = placeTest(brd1);
erase.setLabel('Tap to Test Your Answer')
erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
erase.on('over', function () {this.label.setAttribute({visible:true});});
erase.on('out', function () {this.label.setAttribute({visible:false});});
//Erase Function
var check = function(){
  if(i==j)
  {rght.setAttribute({visible:true});}
  else {
   wrng[0].setAttribute({visible:true});
  }
}
erase.on('down',function(){
  //txt0.setAttribute({visible: true});
  //dash0.setAttribute({visible: true});
  rght.setAttribute({visible:false});
  wrng[0].setAttribute({visible:false});
  check();
  txt1.setAttribute({visible: true});
  dash1.setAttribute({visible: true});
  txt2.setAttribute({visible: true});
  dash2.setAttribute({visible: true});
  txt3.setAttribute({visible: true});
  dash3.setAttribute({visible: true});
});
//Shuffle function
analytics.on('down',function(){
  rght.setAttribute({visible:false});
  chk[0].setAttribute({visible:false});
  chk[1].setAttribute({visible:false});
  chk[2].setAttribute({visible:false});
  wrng[0].setAttribute({visible:false});
    txt0.setAttribute({visible: false});
    dash0.setAttribute({visible: false});
    txt1.setAttribute({visible: false});
    ans1.setAttribute({color:'gray'});
    dash1.setAttribute({visible: false});
    txt2.setAttribute({visible: false});
    ans2.setAttribute({color:'gray'});
    dash2.setAttribute({visible: false});
    txt3.setAttribute({visible: false});
    dash3.setAttribute({visible: false});
    ans3.setAttribute({color:'gray'});
    a = Math.round(Math.random()*8)+1;
    b = Math.round(Math.random()*3)+1;
    c = Math.round(Math.random()*6)+1;
    d = Math.round(Math.random()*7)+1;
    j = Math.round(Math.random()*2)+1;
    arr=[a,b,c,d];
    //coefficient1.Value() = 'ppp'; // I want to reset the input values
});
brd1.unsuspendUpdate();
}
}
export default Boxes;
